<template>
  <div>
    <el-dialog :visible.sync="dialogFormVisible"
               :show-close="false"
               @open="open">
      <div slot="title"
           style="font-weight:700;font-size:16px">{{dialogTitle}}</div>
      <el-form label-position="top"
               :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               style="text-align: left">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="开户行"
                          prop="type">
              <el-select v-model="ruleForm.type"
                         placeholder="请选择开通的银行"
                         :disabled="dialogTitle.includes('编辑')"
                         style="width:100%"
                         @change="changeBank">
                <el-option v-for="(item,index) in bankDepositList"
                           :key="index"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="110px"
                          prop="platformName">
              <span slot="label">
                渠道名称
                <span style="font-size:12px;color:#aabdd6">( 渠道编号对应的名称，例如: 西咸零工保-平安银行 )</span>
              </span>
              <el-input v-model="ruleForm.platformName"
                        placeholder="请输入渠道名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24"
                v-if="!bankDeposit">
          <el-col :span="12">
            <el-form-item prop="accountName">
              <span slot="label">
                账户名称
                <span style="font-size:12px;color:#aabdd6">( 开户的企业名称 )</span>
              </span>
              <el-input v-model="ruleForm.accountName"
                        placeholder="请输入账户名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="110px"
                          prop="accountCode">
              <span slot="label">
                账户卡号
                <span style="font-size:12px;color:#aabdd6">( 企业公户卡号 )</span>
              </span>
              <el-input v-model="ruleForm.accountCode"
                        placeholder="请输入账户卡号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24"
                v-if="bankDeposit">
          <el-col :span="12"
                  style="float: left;">
            <el-form-item prop="MerchantId">
              <span slot="label">商户编号</span>
              <el-input v-model.trim="ruleForm.MerchantId"
                        placeholder="请输入商户编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  style="float: left;">
            <el-form-item prop="appId">
              <span slot="label">商户APPID</span>
              <el-input v-model.trim="ruleForm.appId"
                        placeholder="请输入商户APPID"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24"
                v-if="bankDeposit">
          <el-col :span="12"
                  style="float: left;">
            <el-form-item prop="rsa_pub">
              <span slot="label">商户公钥 <span style="font-size:12px;color:red">( 请勿随意更改 )</span></span>

              <el-input v-model.trim="ruleForm.rsa_pub"
                        ref="rsa_pub"
                        rows="5"
                        type="textarea"
                        placeholder="请输入商户公钥"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="rsa">
              <span slot="label">商户私钥 <span style="font-size:12px;color:red">( 请勿随意更改 )</span></span>

              <el-input type="textarea"
                        rows="5"
                        ref="refRsa"
                        v-model.trim="ruleForm.rsa"
                        placeholder="请输入商户私钥"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="24"
                v-if="!bankDeposit">
          <el-col :span="12"
                  style="float: left;">
            <el-form-item prop="pingAnB2bicBusiName">
              <span slot="label">费项名称</span>
              <el-input v-model="ruleForm.pingAnB2bicBusiName"
                        placeholder="请输入费项名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="pingAnB2bicBusiType">
              <span slot="label">行内费项代码</span>
              <el-input v-model="ruleForm.pingAnB2bicBusiType"
                        placeholder="请输入行内费项代码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24"
                v-if="!bankDeposit">
          <el-col :span="12">
            <el-form-item label-width="110px"
                          prop="pingAnB2bicBusiType2">
              <span slot="label">跨行费项代码</span>
              <el-input v-model="ruleForm.pingAnB2bicBusiType2"
                        placeholder="请输入跨行费项代码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行内协议编号"
                          prop="pingAnB2bicAgreeNo">
              <el-input v-model="ruleForm.pingAnB2bicAgreeNo"
                        placeholder="请输入行内协议编号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24"
                v-if="!bankDeposit">
          <el-col :span="12">
            <el-form-item label="跨行协议编号"
                          prop="pingAnB2bicAgreeNo2">
              <el-input :disabled="bankDeposit"
                        v-model="ruleForm.pingAnB2bicAgreeNo2"
                        placeholder="请输入跨行协议编号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button type="primary"
                   v-if="dialogTitle==='新增账户信息'"
                   @click="onSubmit('ruleForm')">提 交</el-button>
        <el-button type="primary"
                   v-else
                   @click="goSubmit('ruleForm')">编 辑</el-button>
        <el-button @click="cancel('ruleForm')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>


import { api } from '../../api/base/index';
export const enterpriseBankCardAdd = api()('enterprise.bankCard.add.json');
export const getAllPayCallBackUrl = api()('getAllPayCallBackUrl.json');
export const enterpriseInfo = api()('enterprise.info.json');


export const enterprisePayUpdate = api()('enterprise.pay.update.json');


export default {
  props: {
    dialogFormVisible: Boolean,
    dialogTitle: String,
    item: Object,
    code: String

  },
  data () {
    return {
      platformCode: '',

      smsCode: '',
      bankDepositList: [
        {
          label: '网商银行',
          value: 'mybank2',
        },
        {
          label: '平安银行',
          value: 'pingan',
        }
      ],

      ruleForm: {
        type: null,
        // cbuType: '',
        platformName: '',
        MerchantId: '',
        appId: '',
        rsa: '',
        rsa_pub: '',
        accountCode: '',
        accountName: '',
        pingAnB2bicBusiName: '',
        pingAnB2bicBusiType: '',
        pingAnB2bicAgreeNo2: '',
        pingAnB2bicAgreeNo: '',
        businessNo: '',
        pingAnB2bicBusiType2: '',
        businessName: ''
      },
      rules: {
        MerchantId: [{ required: true, message: '请输入商户编号', trigger: 'blur' },],
        rsa_pub: [{ required: true, message: '请输入商户公钥', trigger: 'blur' }],
        rsa: [{ required: true, message: '请输入商户私钥', trigger: 'blur' }],
        appId: [{ required: true, message: '请输入商户APPID', trigger: 'blur' }],
        type: [{ required: true, message: '请选择开户行', trigger: 'blur' },],

        // cbuType: [{ required: true, message: '请选择通知回调地址', trigger: 'blur' }],
        // platformName: [{ required: true, message: '请输入渠道名称', trigger: 'blur' }],
        // accountName: [{ required: false, message: '请输入账户名称', trigger: 'blur' }],
        accountCode: [{ required: false, message: '请输入账户卡号', trigger: 'blur' }],
        pingAnB2bicBusiName: [{ required: true, message: '请输入费项名称', trigger: 'blur' }],
        pingAnB2bicBusiType: [{ required: true, message: '请输入费项代码', trigger: 'blur' }],
        pingAnB2bicAgreeNo: [{ required: true, message: '请输入费项编号', trigger: 'blur' }],
        businessName: [{ required: true, message: '请输入业务名称', trigger: 'blur' }]
      },
      defaultVisible: false,
      bankDeposit: false,

    }
  },
  components: {
    // Divider: () => import("../../components/divider.vue"),
  },

  methods: {
    open () {
      if (this.dialogTitle.includes('编辑')) {
        this.editAccount(this.item)
      }
      else {
        this.bankDeposit = false
        this.ruleForm.type = null
        this.ruleForm.platformName = '',
          // this.ruleForm.accountCode = '',
          // this.ruleForm.accountName = '',
          this.ruleForm.pingAnB2bicBusiName = '',
          this.ruleForm.pingAnB2bicBusiType = '',
          this.ruleForm.pingAnB2bicAgreeNo2 = '',
          this.ruleForm.pingAnB2bicAgreeNo = '',
          this.ruleForm.businessNo = '',
          this.ruleForm.pingAnB2bicBusiType2 = '',
          this.ruleForm.businessName = ''
      }
    },
    changeBank (e) {
      if (e === 'pingan') {
        this.bankDeposit = false;
        this.ruleForm.pingAnB2bicBusiType = ''
        this.ruleForm.pingAnB2bicAgreeNo = ''
        this.ruleForm.pingAnB2bicBusiName = ''
      }
      else {
        this.bankDeposit = true
        this.rules.rsa[0].required = true;
        this.rules.rsa_pub[0].required = true;
        this.ruleForm.pingAnB2bicBusiType = '0'
        this.ruleForm.pingAnB2bicAgreeNo = '0'
        this.ruleForm.pingAnB2bicBusiName = '0'
      }
    },
    cancel (formName) {
      this.$refs[formName].resetFields();
      this.$emit('update:dialogFormVisible', false)
      this.$emit('close');
    },
    editAccount (item) {
      console.log(this.$refs, this.rules);

      this.$nextTick(() => {
        this.$refs.refRsa.$attrs.placeholder = '为保障您的安全我们不显示私钥'
        this.$refs.rsa_pub.$attrs.placeholder = '为保障您的安全我们不显示公钥'
        this.rules.rsa[0].required = false;
        this.rules.rsa_pub[0].required = false;
      })

      this.platformCode = item.platformCode
      if (item.bankName === '网商银行') {
        this.ruleForm.type = '网商银行'
        // this.ruleForm.cbuType = item.cbuType
        this.ruleForm.platformName = item.platformName
        // this.ruleForm.accountCode = item.MerchantMyBankCardNo
        // this.ruleForm.accountName = item.MerchantMyBankCardName
        // this.$refs.refRsa.$attrs.placeholder = '1'
        this.ruleForm.rsa_pub = item.rsa_pub
        this.ruleForm.appId = item.appId
        this.ruleForm.MerchantId = item.MerchantId
        this.ruleForm.pingAnB2bicBusiType = '0'
        this.ruleForm.pingAnB2bicAgreeNo = '0'
        this.ruleForm.pingAnB2bicBusiName = '0'
        this.bankDeposit = true
      }
      else {
        this.ruleForm.type = '平安银行'
        // this.ruleForm.cbuType = item.cbuType
        this.ruleForm.platformName = item.platformName
        this.ruleForm.accountCode = item.accountCode
        this.ruleForm.accountName = item.accountName
        this.ruleForm.pingAnB2bicBusiName = item.pingAnB2bicBusiName
        this.ruleForm.pingAnB2bicAgreeNo = item.pingAnB2bicAgreeNo.split(',')[0]
        this.ruleForm.pingAnB2bicAgreeNo2 = item.pingAnB2bicAgreeNo.split(',')[1]
        this.ruleForm.pingAnB2bicBusiType = item.pingAnB2bicBusiType.split(',')[0]
        this.ruleForm.pingAnB2bicBusiType2 = item.pingAnB2bicBusiType.split(',')[1]
        this.bankDeposit = false
      }
    },
    goSubmit (formName) {
      let obj = {}
      if (this.ruleForm.type === '平安银行') {

        obj['platformCode'] = this.platformCode
        // obj['cbuType'] = this.ruleForm.cbuType
        obj['platformName'] = this.ruleForm.platformName
        obj['accountCode'] = this.ruleForm.accountCode
        obj['accountName'] = this.ruleForm.accountName
        obj['pingAnB2bicBusiName'] = this.ruleForm.pingAnB2bicBusiName
        if (this.ruleForm.pingAnB2bicAgreeNo2) {
          obj['pingAnB2bicAgreeNo'] = `${this.ruleForm.pingAnB2bicAgreeNo},${this.ruleForm.pingAnB2bicAgreeNo2}`
        }
        else {
          obj['pingAnB2bicAgreeNo'] = `${this.ruleForm.pingAnB2bicAgreeNo}`
        }
        if (this.ruleForm.pingAnB2bicBusiType2) {
          obj['pingAnB2bicBusiType'] = `${this.ruleForm.pingAnB2bicBusiType},${this.ruleForm.pingAnB2bicBusiType2}`
        }
        else {
          obj['pingAnB2bicBusiType'] = `${this.ruleForm.pingAnB2bicBusiType}`
        }
        // obj['pingAnB2bicAgreeNo'] = `${this.ruleForm.pingAnB2bicAgreeNo},${this.ruleForm.pingAnB2bicAgreeNo2}`
        // obj['pingAnB2bicBusiType'] = `${this.ruleForm.pingAnB2bicBusiType},${this.ruleForm.pingAnB2bicBusiType2}`
        obj['accountCode'] = this.ruleForm.accountCode
      }
      else {
        obj['platformCode'] = this.platformCode
        // obj['cbuType'] = this.ruleForm.cbuType
        obj['platformName'] = this.ruleForm.platformName
        // obj['MerchantMyBankCardNo'] = this.ruleForm.accountCode
        // obj['MerchantMyBankCardName'] = this.ruleForm.accountName
        if (this.ruleForm.rsa) {
          obj['rsa'] = this.ruleForm.rsa
        }
        if (this.ruleForm.rsa_pub) {
          obj['rsa_pub'] = this.ruleForm.rsa_pub
        }
        obj['appId'] = this.ruleForm.appId
        obj['MerchantId'] = this.ruleForm.MerchantId
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...obj,
          };
          enterprisePayUpdate(params).then((res) => {
            console.log(res);
            this.$message.success('编辑成功')
            this.cancel(formName);

          });
        } else {
          return false;
        }
      });
    },
    onSubmit (formName) {
      let obj = {}
      if (this.ruleForm.type === 'pingan') {
        obj['type'] = this.ruleForm.type
        // obj['cbuType'] = this.ruleForm.cbuType
        obj['platformName'] = this.ruleForm.platformName
        obj['accountCode'] = this.ruleForm.accountCode
        obj['accountName'] = this.ruleForm.accountName
        obj['pingAnB2bicBusiName'] = this.ruleForm.pingAnB2bicBusiName
        if (this.ruleForm.pingAnB2bicAgreeNo2) {
          obj['pingAnB2bicAgreeNo'] = `${this.ruleForm.pingAnB2bicAgreeNo},${this.ruleForm.pingAnB2bicAgreeNo2}`
        }
        else {
          obj['pingAnB2bicAgreeNo'] = `${this.ruleForm.pingAnB2bicAgreeNo}`
        }
        if (this.ruleForm.pingAnB2bicBusiType2) {
          obj['pingAnB2bicBusiType'] = `${this.ruleForm.pingAnB2bicBusiType},${this.ruleForm.pingAnB2bicBusiType2}`
        }
        else {
          obj['pingAnB2bicBusiType'] = `${this.ruleForm.pingAnB2bicBusiType}`
        }
        obj['accountCode'] = this.ruleForm.accountCode
      }
      else {
        obj['type'] = this.ruleForm.type
        // obj['cbuType'] = this.ruleForm.cbuType
        obj['platformName'] = this.ruleForm.platformName
        obj['accountCode'] = this.ruleForm.accountCode
        obj['accountName'] = this.ruleForm.accountName
        obj['rsa'] = this.ruleForm.rsa
        obj['rsa_pub'] = this.ruleForm.rsa_pub
        obj['appId'] = this.ruleForm.appId
        obj['MerchantId'] = this.ruleForm.MerchantId
        console.log(this.ruleForm)
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            enterpriseCode: this.code,
            ...obj
          };
          enterpriseBankCardAdd(params).then((res) => {
            console.log(res);
            this.$message.success('添加成功')
            this.cancel(formName);
          });
        } else {
          return false;
        }
      });
    },
  },
}
</script>
<style >
</style>
<style lang="scss" scoped>
.bank_Calss {
  width: 100%;
  border-radius: 5px;
  border: #8798af solid 1px;
  margin: 10px 0;
  .class_top {
    background: #f2f2f2;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.el-descriptions-item__content {
  white-space: normal;
  word-wrap: break-word;
  width: 200px;
}
.el-divider {
  background: #e5e5e5 !important;
}
</style>